import React from 'react';
import styled from 'styled-components';
import { coord } from './types';

interface LoadingSpinnerProps {
    position: coord;
}

const LoadingSpinner = ({ position }: LoadingSpinnerProps) => {
    return <Spinner strokeDasharray={157} cx={position.x} cy={position.y} r={0.25} fill="transparent" strokeWidth={0.1} />;
};

export default LoadingSpinner;

const Spinner = styled.circle`
    stroke: #93bfec;
    stroke-linecap: round;
    stroke-opacity: 0.75;
    animation: dash 1.5s ease-in-out infinite;


    @keyframes rotate {
        100% {
          transform: rotate(360deg);
        }
      }

    @keyframes dash {
        0% {
          stroke-dasharray: 1, 4;
          stroke-dashoffset: 0;
        }
        50% {
          stroke-dasharray: 2, 4;
          stroke-dashoffset: -1;
        }
        100% {
          stroke-dasharray: 2, 4;
          stroke-dashoffset: -3;
        }
      }
`;
