import { useCallback, useEffect, useState } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { coord, MessageType, Message, IMAGE_DIM, Pixel } from "./types";

const usePlace = () => {
  const { sendMessage, lastMessage, readyState } = useWebSocket(
    "wss://4cff522zx3.execute-api.eu-west-2.amazonaws.com/production"
  );

  const [image, setImage] = useState<Pixel[][]>(
    Array.from({ length: 10 }, (_, i) =>
      Array.from({ length: 10 }, (_, j) => ({
        color: (i + j) % 2 === 0 ? "red" : "blue",
        index: (i + j),
      }))
    )
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (readyState === ReadyState.OPEN && lastMessage) {
      const message = JSON.parse(lastMessage.data) as Message;
      switch (message.type) {
        case MessageType.Image:
          message.Items.sort((a, b) => a.index - b.index);
          setImage(
            Array.from({ length: IMAGE_DIM }, (_, i) =>
              Array.from(
                { length: IMAGE_DIM },
                (_, j) => message.Items[i * IMAGE_DIM + j]
              )
            )
          );
          setLoading(false);
          break;
        case MessageType.Update:
          setImage((currentImage) => {
            const newImage = JSON.parse(JSON.stringify(currentImage));
            message.Items.forEach((item) => {
              newImage[Math.floor(item.index / IMAGE_DIM)][
                item.index % IMAGE_DIM
              ] = item;
            });
            return newImage;
          });
      }
    }
  }, [lastMessage, readyState]);

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      const request = { action: "getImage" };
      sendMessage(JSON.stringify(request));
    }
  }, [readyState, sendMessage]);

  const setPixel = useCallback(
    (color: string, position: coord) => {
      const request = {
        action: "place",
        index: position.y * IMAGE_DIM + position.x,
        color,
      };
      sendMessage(JSON.stringify(request));
      setImage((currentImage) => {
        const newImage = JSON.parse(JSON.stringify(currentImage));
        newImage[position.y][position.x].loading = true;
        return newImage;
      });
    },
    [sendMessage]
  );

  return { image, setPixel, loading, readyState };
};

export default usePlace;
