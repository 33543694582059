export interface coord {
  x: number;
  y: number;
}

export const IMAGE_DIM = 10;

export interface Pixel {
  index: number;
  timestamp?: number;
  color: string;
  userId?: string;
  loading?: boolean;
}

export enum MessageType {
  Image = "IMAGE",
  Update = "UPDATE",
}

export interface Message {
  Items: Pixel[];
  type: MessageType;
}
