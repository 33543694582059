import React, { useCallback, useRef, useState } from "react";
import usePlace from "./usePlace";
import ColorPicker from "./ColorPicker";
import { coord } from "./types";
import styled from 'styled-components';
import LoadingSpinner from './LoadingSpinner';
import LoadScreen from "./LoadScreen";
import ConnectionIndicator from "./ConnectionIndicator";
import { ReadyState } from "react-use-websocket";

const PlacePage = () => {
  const { image, setPixel, loading, readyState } = usePlace();
  const svgRef = useRef<SVGSVGElement>(null);
  const [selected, setSelected] = useState<coord | undefined>();

  const onColorPick = useCallback(
    (color: string) => {
      selected && setPixel(color, selected);
      setSelected(undefined);
    },
    [selected, setPixel]
  );

  return (
    <Frame>
      <ConnectionIndicator online={readyState === ReadyState.OPEN} />
      {selected && svgRef.current &&
      <ColorPicker
        position={selected}
        imageHeight={svgRef.current?.getBoundingClientRect().height}
        imageWidth={svgRef.current?.getBoundingClientRect().width}
        onPick={onColorPick}
      />}
      {loading && <LoadScreen />}
      {!loading && <svg viewBox="0 0 10 10" ref={svgRef}>
        {image.map((row, y) =>
          row.map((pixel, x) => {
            const isSelected = selected?.x === x && selected?.y === y;
            return (
              <>
              <rect
                key={`${x}-${y}-${pixel.color}`}
                y={isSelected ? y + 0.05 : y}
                x={isSelected ? x + 0.05 : x}
                width={isSelected ? 0.9 : 1}
                height={isSelected ? 0.9 : 1}
                fill={pixel.color}
                onClick={(h) => 
                  setSelected(isSelected ? undefined : { x, y })
                }
                {...(isSelected ? { stroke: "black", strokeWidth: "0.1" } : {})}
              />
              {pixel.loading && <LoadingSpinner position={{x: x + 0.5, y: y + 0.5}} />}
              </>
            );
          })
        )}
      </svg>}
    </Frame>
  );
};

export default PlacePage;

const Frame = styled.div`
  display: flex;
  height: 100vh;
`;
