import React, { useCallback } from "react";
import { GithubPicker, ColorResult } from "react-color";
import { coord, IMAGE_DIM } from "./types";
import styled from "styled-components";

interface ColorPickerProps {
  onPick: (color: string) => void;
  position: coord;
  imageWidth: number;
  imageHeight: number;
}

const ColorPicker = ({ onPick, position, imageHeight, imageWidth }: ColorPickerProps) => {
  const onChange = useCallback((color: ColorResult) => onPick(color.hex), [
    onPick,
  ]);

  const pixelWidth = Math.floor(imageWidth / IMAGE_DIM);
  const pixelHeight = Math.floor(imageHeight / IMAGE_DIM);
  const top = (position.y + 1) * pixelHeight;
  const left = (position.x * pixelWidth) + Math.floor(pixelWidth / 2) - 20;

  return (
    <Wrapper top={top} left={left}>
      <GithubPicker onChange={onChange} />
    </Wrapper>
  );
};

export default ColorPicker;

const Wrapper = styled.div<{ top: number; left: number }>`
  position: absolute;
  top: ${({top}) => top}px;
  left: ${({left}) => left}px;
`;
