import React from 'react';
import LoadingSpinner from './LoadingSpinner';

const LoadScreen = () => {
    return (
        <svg viewBox="0 0 5 5">
            <LoadingSpinner position={{ x: 2.5, y: 2.5 }} />
        </svg>
    );
};

export default LoadScreen
