import React from 'react';
import styled from 'styled-components';

interface ConnectionIndicatorProps {
    online: boolean;
}

const ConnectionIndicator = ({ online }: ConnectionIndicatorProps) => {
    return (
        <Wrapper>
            <Light online={online} />
            {online ? 'Online' : 'Offline'}
        </Wrapper>
    )
};

export default ConnectionIndicator;

const Light = styled.div<{ online: boolean }>`
    width: 1em;
    height: 1em;
    border-radius: 0.5em;
    background-color: ${({ online }) => online ? 'green' : 'red'};
    margin-right: 0.5em;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 1em;
    top: 1em;
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,0.75);
    align-items: center;
    padding: 0.5em;
    border-radius: 0.2em;
`;
